<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.basic_status_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <b-tabs content-class="mt-3"  class="nav-custom-link">
        <b-tab :title="$t('basic_status_management.basic_information')" active>
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('basic_status_management.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('basic_status_management.slug') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.slug" class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                    <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.slug[0] }}
                            </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('basic_status_management.module_type') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.module_type" class="custom-select" :class="validation && validation.module_type ? 'is-invalid' : ''">
                      <option v-for="(row, index) in module_type_list" :value="row.id" :key="'module_type'+index">{{row.name}}</option>
                    </select>
                    <span v-if="validation && validation.module_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.module_type[0] }}
                            </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('basic_status_management.order') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.order_status" class="custom-select" :class="validation && validation.order_status ? 'is-invalid' : ''">
                      <option v-for="(row, index) in order_count" :key="'order'+index">{{row}}</option>
                    </select>
                    <span v-if="validation && validation.order_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.order_status[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_required" name="check-button" switch>{{ $t('basic_status_management.is_required') }}</b-form-checkbox>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('basic_status_management.Detailed_data_documents')">
          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('basic_status_management.Detailed_data_documents') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeaterDocument">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeaterDocument" @keyup.alt.46="deleteFromRepeaterDocument(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('basic_status_management.doc_type') }}</th>
                      <th>{{ $t('status') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(doc, index) in documents" :key="index">
                      <td>
                        <select type="text" v-model="doc.doc_type_id" class="custom-select">
                          <option v-for="(row, index) in doc_type_list" :value="row.document_slug" :key="'doc_type'+index">{{row.document_name}}</option>
                        </select>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeaterDocument(index)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('basic_status_management.Detailed_data_users_and_permissions')">
          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('basic_status_management.Detailed_data_permissions') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeaterPermission">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeaterPermission" @keyup.alt.46="deleteFromRepeaterPermission(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('basic_status_management.permission') }}</th>
                      <th>{{ $t('status') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(doc, index) in permissions" :key="index">
                      <td>
                        <select type="text" v-model="doc.role_id" class="custom-select">
                          <option v-for="(row, index) in permissions_list" :value="row.id" :key="'permission'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeaterPermission(index)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('basic_status_management.Detailed_data_users') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeaterUser">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeaterUser" @keyup.alt.46="deleteFromRepeaterUser(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('basic_status_management.user') }}</th>
                      <th>{{ $t('status') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(doc, index) in users" :key="index">
                      <td>
                        <select type="text" v-model="doc.user_id" class="custom-select">
                          <option v-for="(row, index) in users_list" :value="row.id" :key="'user'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeaterUser(index)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>






      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from",
  data() {
    return {
      mainRoute: 'settings/documents_status',
      mainRouteDependency: 'base/dependency',
      items: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        slug: null,
        module: null,
        module_type: null,
        order_status: null,
        is_required: true,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      repeater_document: {id: null, doc_type_id: null, is_active: false},
      documents: [],
      repeater_permission: {id: null, role_id: null, is_active: false},
      permissions: [],
      repeater_user: {id: null, user_id: null, is_active: false},
      users: [],

      module_type_list: [],
      doc_type_list: [],
      permissions_list: [],
      users_list: [],
      order_count: 40,
    };
  },
  watch:{
    'data.module_type': function (val){
      if (val){
        this.getDocType(val)
      }else {
        this.doc_type_list = [];
      }
    }
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      }
      // else {
      //   this.create();
      // }
    },

    // create() {
    //
    //   ApiService.post(this.mainRoute, {
    //     ...this.data,
    //     items: this.data_items,
    //   }).then((response) => {
    //     this.validation = null;
    //     this.$successAlert(response.data.message);
    //     this.$router.push('/settings/basic-status-management');
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //     this.validation = error.response ? error.response.data.errors : null;
    //   });
    // },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        applied_document: this.documents,
        access_roles: this.permissions,
        special_users: this.users,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/basic-status-management');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.slug = response.data.data.slug;
        this.data.module_type = response.data.data.module_type;
        this.data.module = response.data.data.module;
        this.data.order_status = response.data.data.order_status;
        this.data.is_required = response.data.data.is_required;
        this.data.is_active = response.data.data.is_active;

        this.documents = response.data.data.applied_document ? response.data.data.applied_document : [];
        this.permissions = response.data.data.access_roles ? response.data.data.access_roles : [];
        this.users = response.data.data.special_users ? response.data.data.special_users : [];
      });
    },

    addToRepeaterDocument() {
      this.documents.unshift(this.repeater_document);
      this.repeater_document = {id: null, doc_type_id: null, is_active: false};
    },
    deleteFromRepeaterDocument(index) {
      if (this.documents.length > 1)
        this.documents.splice(index, 1);
    },

    addToRepeaterPermission() {
      this.permissions.unshift(this.repeater_permission);
      this.repeater_permission = {id: null, role_id: null, is_active: false};
    },
    deleteFromRepeaterPermission(index) {
      if (this.permissions.length > 1)
        this.permissions.splice(index, 1);
    },

    addToRepeaterUser() {
      this.users.unshift(this.repeater_user);
      this.repeater_user = {id: null, user_id: null, is_active: false}
    },
    deleteFromRepeaterUser(index) {
      if (this.users.length > 1)
        this.users.splice(index, 1);
    },
    getRoles() {
      ApiService.get(this.mainRouteDependency+"/roles").then((response) => {
        this.permissions_list = response.data.data;
      });
    },
    getUsers() {
      ApiService.get(this.mainRouteDependency+"/users").then((response) => {
        this.users_list = response.data.data
      });
    },
    async getModuleType() {
      await ApiService.get(this.mainRouteDependency+"/modules").then((response) => {
        this.module_type_list = response.data.data
      });
    },
    getDocType(id) {
      if (id){
        let _slug = '';
        let _row = this.module_type_list.find(row => row.id == id);
        if (_row){
          _slug = _row.slug;
          this.data.module = _row.slug;
          ApiService.get(this.mainRouteDependency+"/module_document?module_type="+_slug).then((response) => {
            this.doc_type_list = response.data.data
          });
        }

      }

    },



  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.basic_status_management"), route: '/settings/basic-status-management'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getRoles();
    this.getUsers();
    let promise = this.getModuleType();
    if (this.idEdit) {
      Promise.all([promise]).then(()=>{
        this.getData();
      })

    }
    // else {
    //   if (this.data_items.length <= 0) {
    //     this.addToRepeater();
    //   }
    // }

  },
};
</script>


